/* anton-regular - latin */
@font-face {
    font-family: "Anton";
    font-style: normal;
    font-weight: 400;
    src: url("./anton-v12-latin-regular.eot");
    src: local(""), url("./anton-v12-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("./anton-v12-latin-regular.woff2") format("woff2"), url("./anton-v12-latin-regular.woff") format("woff"),
        url("./anton-v12-latin-regular.ttf") format("truetype"),
        url("./anton-v12-latin-regular.svg#Anton") format("svg");
    font-display: swap;
}

@font-face {
    font-family: "abeezee";
    font-style: normal;
    font-weight: 500;
    src: url("./abeezee-v14-latin-regular.eot");
    src: local(""), url("./abeezee-v14-latin-regular.eot?#iefix") format("embedded-opentype"),
        url("./abeezee-v14-latin-regular.woff2") format("woff2"), url("./abeezee-v14-latin-regular.woff") format("woff"),
        url("./abeezee-v14-latin-regular.ttf") format("truetype"),
        url("./abeezee-v14-latin-regular.svg#Karla") format("svg");
    font-display: swap;
}
