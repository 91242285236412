$color1: #132e4b;
$color1_ee: #132e4bee;
$color2: #2c699a;
$color3: #048ba8;
$color4: #23a59f;
$color5: #16db93;
$color6: #83e377;
$color7: #b9e769;

$primary: #40a09e;
$accent: #1d293f;
$error: #8a0000;

$maxWidthMobile: 600px;
$maxWidthMedium: 1000px;

$headerHeight: 115px;
$headerHeightMobile: 85px;

$veryLightGreen: #fafefe;
