@import "variables";

body {
  transition: all 0.5s ease-in-out;

  padding-top: #{$headerHeight};
  margin: 0 0;

  background-color: $veryLightGreen;
  font-family: "abeezee";

  -ms-overflow-style: none;

  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none; // Safari and Chrome
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "anton";
  margin: 0;
}

h5 {
  color: $accent;
}

.button-link {
  display: inline-flex;
  text-decoration: none;

  box-shadow: 2px 2px 2px $color1_ee;

  text-align: center;
  font-weight: bold;

  margin: 0 10px;
  padding: 10px 20px;

  border-radius: 5px;
  cursor: pointer;

  line-height: 25px;
  height: 25px;

  transition: all 0.1s ease-in-out;

  &:hover {
    transform: scaleX(1.05);
    background: $color4;
  }
}

html {
  scroll-behavior: smooth !important;
}

* {
  scroll-margin-top: $headerHeight;
}

h2 {
  color: $accent;
  font-size: 58px;
  line-height: 64px;
}

h3 {
  color: $accent;
  font-size: 35px;
  line-height: 50px;
}

.section-body {
  padding: 0 20%;
}

@media screen and (min-width: 1000px) and (max-width: 1500px) {
  .section-body {
    padding: 0 10%;
  }
}

@media screen and (max-width: 1000px) {
  .section-body {
    padding: 0 5%;
  }
}

@media screen and (max-width: $maxWidthMobile) {
  body {
    padding-top: #{$headerHeightMobile};
  }

  * {
    scroll-margin-top: $headerHeightMobile;
  }
}
