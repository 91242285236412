@import "variables";

header {
    position: relative;
}

.header {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 6;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.95);

    font-family: "abeezee";

    display: flex;
    flex-direction: column;

    height: $headerHeight;
}

.header__contact_info {
    background-color: $accent;

    color: white;
    line-height: 25px;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;

    & a {
        color: white;
        text-decoration: none; /* no underline */
    }
}

.header__contact_info span {
    &:first-of-type {
        margin-right: 30px;
    }
}

.header__container {
    display: flex;
    -moz-box-pack: justify;
    justify-content: space-between;
    -moz-box-align: center;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    transition: all 0.5s ease-in-out;
    width: 80%;

    flex: 1;
}

.header__logo {
    flex-wrap: wrap;
    -moz-box-pack: start;
    justify-content: flex-start;
    -moz-box-align: center;
    align-items: center;

    margin-right: 16px;
    display: flex;

    transition: transform 0.3s ease-in-out;

    z-index: 1000;
}

.header__logo:hover {
    transform: scale(1.15);
}

.header__logo img {
    transition: width 0.5s ease-in-out;
    width: 70px;
}

.header__mobile_nav {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    text-align: center;
    background: #fff;
    opacity: 0;
    z-index: -1;
    visibility: hidden;
    transition: all 0.375s;
    height: 100vh;

    .is-open & {
        opacity: 1;
        z-index: 100;
        visibility: visible;

        &::before {
            transform: skew(-14deg) translateX(0);
        }

        & a {
            opacity: 1;
            transform: translateY(0);
        }
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: -15px;
        background-color: #e9f7fe;
        transform-origin: 0 0;
        transform: skew(-14deg) translateX(-120%);
        transition: all 0.275s 0.1s;
    }

    & ul {
        display: inline-flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-end;
        justify-content: center;
        transform: translateX(-18%) skew(-26deg);
    }

    & li {
        display: block;
        margin: 0.5rem 0;
        text-align: right;
        transform: skew(26deg);
    }

    & a {
        display: block;
        padding: 12px 0;
        color: $color1;
        font-size: 1.4em;
        text-decoration: none;
        font-weight: bold;
        opacity: 0;
        transform: translateY(-10px);
    }

    & li:nth-child(1) a {
        transition: all 275ms 175ms;
    }

    & li:nth-child(2) a {
        transition: all 275ms 355ms;
    }

    & li:nth-child(3) a {
        transition: all 275ms 555ms;
    }

    & li:nth-child(4) a {
        transition: all 275ms 755ms;
    }

    & li:nth-child(n) a {
        transition: color 275ms 175ms;
        transition: background-color 275ms 175ms;
    }
}

.header__nav {
    display: flex;
}

@media screen and (max-width: $maxWidthMobile) {
    .header {
        height: $headerHeightMobile;
    }

    .header__contact_mail {
        display: none;
    }

    .header__container {
        width: 95%;
    }

    .header__logo:hover {
        transform: unset;
    }

    .header__logo img {
        width: 50px;
    }

    .header__nav {
        display: none;
    }
}

.header__nav_container {
    .nav {
        position: relative;
        text-decoration: none;
        margin: 0 20px;

        transition: color 0.1s ease-in-out;
        color: $color1;
    }

    .nav::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: -3px;
        left: 0;
        background-color: $color4;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
    }

    .nav:hover::before {
        visibility: visible;
        transform: scaleX(1);
    }

    .nav:hover {
        color: $color4;
    }

    .nav.is-active {
        color: $color4;
    }

    .nav.is-active::before {
        transition: all 0s ease-in-out;
        visibility: visible;
        transform: scaleX(1);
    }
}

.open-main-nav {
    position: absolute;
    top: 15px;
    padding-top: 45px;
    right: 15px;
    z-index: 1000;
    background: none;
    border: 0;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    .burger {
        position: relative;
        display: none;
        width: 28px;
        height: 4px;
        margin: 0 auto;
        background: $color1;
        transform: skew(5deg);
        transition: all 0.275s;

        &:after,
        &:before {
            content: "";
            display: block;
            height: 100%;
            background: $color1;
            transition: all 0.275s;
        }

        &:after {
            transform: translateY(-12px) translateX(-2px) skew(-20deg);
        }

        &:before {
            transform: translateY(-16px) skew(-10deg);
        }
    }

    @media screen and (max-width: $maxWidthMobile) {
        .burger {
            display: block;
        }
    }
}

.is-open {
    .burger {
        transform: skew(5deg) translateY(-8px) rotate(-45deg);

        &:before {
            transform: translateY(0px) skew(-10deg) rotate(75deg);
        }

        &:after {
            transform: translateY(-12px) translateX(10px) skew(-20deg);
            opacity: 0;
        }
    }
}

@media screen and (max-width: $maxWidthMedium) and (min-width: $maxWidthMobile) {
    .header__container {
        width: 95%;
    }

    .header__nav_container .nav {
        margin: 0 10px;
    }
}